<template>
    <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="Trebuie sa tin cont de referinta / sku / identificator unic?" name="1">
            <div>Pentru a sincroniza corect produsele nu trebuie sa schimbati identificatorul unic. Pentru opencart acest identificator unic este referinta.</div>
            <div>Daca veti schimba acest atribut la produs, actualizarile nu vor mai functiona si se va importa ca un produs nou.</div>
        </el-collapse-item>
        <el-collapse-item title="Cum se vor sincroniza produsele?" name="2">
            <div>Daca ati mapat corect corect categoriile si caracteristicile, acestea se vor importa automat.</div>
        </el-collapse-item>
        <el-collapse-item title="Ce sunt metodele de plata?" name="3">
            <div>Fiecare comanda are o metoda de plata alocata (plata ramburs, plata cu cardul).</div>
            <div>Create metodele de plata pentru magazinul dumneavoastra sau puteti sa le folositi pe cele detectate (unde este cazul).</div>
        </el-collapse-item>
        <el-collapse-item title="Ce extensie este necesara pentru a conecta magazinul la marketplace?" name="4">
            <div>Pentru OpenCart 3.x este necesar sa folositi extensia <el-link type="primary" href="https://api-mp.cel.ro/market_cdn/module/celmarketplace-oc-3-latest.ocmod.zip" target="_blank">CelMarketplace-OC3</el-link>.</div>
            <br>
            <div>Pentru OpenCart 2.x este necesar sa folositi extensia <el-link type="primary" href="https://api-mp.cel.ro/market_cdn/module/celmarketplace-oc-2-latest.ocmod.zip" target="_blank">CelMarketplace-OC2</el-link>.<br>De asemenea, modulul pentru OpenCart 2.x are urmatoarele dependente:<br>
                <ul>
                    <li><el-link href="https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=18892" type="primary" target="_blank">Quick Fix: FTP support disabled (Necesara la instalare)</el-link></li>
                    <li><el-link href="https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=24529" type="primary" target="_blank">ExtensionUninstaller (Necesara la actualizare)</el-link></li>
                </ul>
            </div>
        </el-collapse-item>
        <Versions />
    </el-collapse>
</template>

<script>
    import Versions from '@/views/integrari/magazin/shops/shared/Tabs/Info/Versions';

    export default {
        data() {
            return {
                activeName: '1'
            };
        },
        components: {
            Versions
        }
    };
</script>
